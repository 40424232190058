import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Transaction } from "../constants";

export interface TransactionsState {
  value: Transaction[];
}

const initialState: TransactionsState = {
  value: [],
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setTransactions: (state, action: PayloadAction<Transaction[]>) => {
      state.value = action.payload;
    },
    addTransactions: (state, action: PayloadAction<Transaction[]>) => {
      state.value.push(...action.payload);
      state.value = state.value.sort((a, b) => b.date - a.date);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTransactions, addTransactions } = transactionsSlice.actions;

export default transactionsSlice.reducer;
