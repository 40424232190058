import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../app/store";
import { owner } from "../../constants";
import styles from "./user-profile.module.css";
import { NftShowroomComponent } from "./nfts/nft-collection-showroom-component";
import {
  add3Dots,
  getOwnerProfilePicture,
} from "../../utils/image_placeholder";
import MediaQuery from "react-responsive";

export const UserProfile = () => {
  const owner: owner = useSelector(
    (state: RootState) => state.modal.data.owner
  );
  const [value, setValue] = useState(0);
  const [img, setImg] = useState(null);
  useEffect(() => {
    getOwnerProfilePicture(owner).then((url) => {
      setImg(url);
    });
  }, []);

  return (
    <div className={styles["column"]}>
      <div className={styles["profile-info-row"]}>
        {img}
        <MediaQuery maxWidth={700}>
          <div className={styles["stats-column"]}>
            <div>Username</div>
            <div>{add3Dots(owner?.username ?? "-", 20)}</div>
          </div>
          <div className={styles["stats-column"]}>
            <div>Address</div>
            <div>{add3Dots(owner.address, 20)}</div>
          </div>
          <div className={styles["stats-column"]}>
            <div>Value</div>
            <div>{value?.toFixed(2) ?? 0}</div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={701}>
          <div className={styles["stats-column"]}>
            <div>Username</div>
            <div>{add3Dots(owner?.username ?? "-", 20)}</div>
          </div>
          <div className={styles["stats-column"]}>
            <div>Address</div>
            <div>{add3Dots(owner.address, 20)}</div>
          </div>
          <div className={styles["stats-column"]}>
            <div>Value</div>
            <div>{value?.toFixed(2) ?? 0}</div>
          </div>
        </MediaQuery>
      </div>
      <div className={styles["nfts"]}>
        <NftShowroomComponent owner={owner} setValue={setValue} />
      </div>
    </div>
  );
};
