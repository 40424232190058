import React, { ReactElement } from "react";
import { IconType } from "react-icons";
import styles from "./page-menu-component.module.css";
import cx from "classnames";
type TileProps = {
  icon: ReactElement;
  iconLabel: string;
  activeTileName: string;
};
const Tile: React.FC<TileProps> = ({
  iconLabel,
  icon,
  activeTileName,
}: TileProps) => {
  return (
    <div
      className={cx(
        styles["tile"],
        activeTileName === iconLabel ? styles["active-tile"] : ""
      )}
    >
      <div className={styles["icon"]}>{icon}</div>
      <label className={styles["icon-label"]}>{iconLabel}</label>
    </div>
  );
};

export { Tile };
