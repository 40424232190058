import React from "react";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { setModalClose } from "../../redux/modalSlice";
import { ModalContent } from "../../constants";
import { UserProfile } from "../user-profile/user-profile";
import { NftShowroomComponent } from "../nft-collection/nft-showroom-component";
import { NftDetailModal } from "../nft-detail/nft-detail-modal";
import { ProfileSettings } from "../profile-settings/profile-settings";
export function MainModal() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 700px)",
  });

  const dispatch = useDispatch();

  const isModalOpen = useSelector((state: RootState) => state.modal.isOpen);
  const modalData = useSelector((state: RootState) => state.modal.data);
  const modalContent = useSelector((state: RootState) => state.modal.content);

  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#1A1A1A",
      borderRadius: "10px",
      padding: "20px",
      fontFamily: "oxanium",
    },
  };
  if (!isDesktop) {
    customStyles = {
      content: {
        top: "3%",
        left: "3%",
        right: "3%",
        bottom: "3%",
        marginRight: "0",
        transform: "translate(0%, 0%)",
        backgroundColor: "#1A1A1A",
        borderRadius: "10px",
        padding: "10px",
        fontFamily: "oxanium",
      },
    };
  }
  return (
    <Modal
      isOpen={isModalOpen}
      onAfterOpen={() => {}}
      onRequestClose={() => dispatch(setModalClose())}
      style={customStyles}
      contentLabel="User data modal"
      ariaHideApp={false}
    >
      {modalContent === ModalContent.USER_PROFILE ? <UserProfile /> : null}
      {modalContent === ModalContent.NFT_COLLECTION ? (
        <NftShowroomComponent />
      ) : null}
      {modalContent === ModalContent.NFT_DETAILS ? <NftDetailModal /> : null}
      {modalContent === ModalContent.USER_SETTINGS ? <ProfileSettings /> : null}
    </Modal>
  );
}
