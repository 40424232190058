import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ModalContent } from "../constants";
import { useDispatch } from "react-redux";
import { store } from "../app/store";
import { ModalHistory } from "../pages/modal/modal-history";

export interface ModalState {
  isOpen: boolean;
  content: ModalContent;
  data: any;
}

// data shoud be:
// NFT_DETAILS: nft collection and token id, owner
// NFT_COLLECTION: nft collection, owner
// USER_PROFILE: owner
// USER_SETTINGS: owner

const initialState: ModalState = {
  isOpen: false,
  content: ModalContent.NONE,
  data: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalOpen: (state) => {
      state.isOpen = true;
    },
    setModalClose: (state) => {
      state.isOpen = false;
      ModalHistory.clear();
    },
    setModalContent: (state, action: PayloadAction<ModalContent>) => {
      state.content = action.payload;
    },
    setModalData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setModalClose, setModalContent, setModalData, setModalOpen } =
  modalSlice.actions;

export default modalSlice.reducer;
