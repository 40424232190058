import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { NftTile } from "./nft-tile";
import styles from "./nft-showroom.module.css";
import { NftCollectionTile } from "./nft-collection-tile";
import { uid } from "../../../../utils/image_placeholder";
import { BsArrowRightCircleFill, BsArrowLeftCircleFill } from "react-icons/bs";
import { useAccount } from "wagmi";
import MediaQuery from "react-responsive";
import { useMediaQuery } from "react-responsive";

const NftShowroomComponent: React.FC = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 700px)",
  });

  let nftsData = useSelector((state: RootState) => state.nfts.value);
  const [leftEnd, setLeftEnd] = useState(true);
  const [rightEnd, setRightEnd] = useState(false);
  const [pageNumber, setpageNumber] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  let tilesInRow = 4;
  const [collection, setCollection] = useState(null);
  let nftContracts = useSelector(
    (state: RootState) => state.nfts_collections.value
  );
  let chosenCollection = useSelector(
    (state: RootState) => state.helper.chosenCollection
  );
  const { address } = useAccount();
  const currentOwner = useSelector((state: RootState) => state.user.owner);
  const collectionMenu = () => (
    <div className={styles["nft-collection-container"]}>
      {isDesktop
        ? nftContracts
            .slice(pageNumber * tilesInRow, (pageNumber + 1) * tilesInRow)
            .map((contract) => (
              <NftCollectionTile
                contract={contract}
                setCollection={setCollection}
                key={uid()}
                owner={currentOwner}
              />
            ))
        : nftContracts.map((contract) => (
            <NftCollectionTile
              contract={contract}
              setCollection={setCollection}
              key={uid()}
              owner={currentOwner}
            />
          ))}
    </div>
  );

  function handleButton(direction: string) {
    let pageTmp = pageNumber;
    if (direction === "left") {
      if (pageNumber > 0) {
        setpageNumber(pageNumber - 1);
        pageTmp--;
      }
    } else {
      if ((pageNumber + 1) * tilesInRow < nftContracts.length) {
        setpageNumber(pageNumber + 1);
        pageTmp++;
      }
    }
    updateButtonStyles(pageTmp);
  }

  function updateButtonStyles(page: number) {
    if (page === 0) {
      setLeftEnd(true);
    } else {
      setLeftEnd(false);
    }
    if (page === 1) {
      setRightEnd(true);
    } else {
      setRightEnd(false);
    }
  }

  return (
    <div>
      <div className={styles["top-row"]}>
        <div className={styles["title-label"]}>
          <div style={{ color: "#00D56E", paddingRight: "10px" }}>My </div>
          <div style={{ color: "#FFFFFF" }}>Assets</div>
        </div>
        <MediaQuery minWidth={700}>
          <div className={styles["scroll-buttons-row"]}>
            <div
              style={{
                background: "none",
                border: "none",
                color: leftEnd ? "#ffffff" : "#00d56e",
              }}
              id="left-button"
              onClick={() => handleButton("left")}
            >
              <BsArrowLeftCircleFill size="25px" />
            </div>
            <div
              style={{
                background: "none",
                border: "none",
                color: rightEnd ? "#ffffff" : "#00d56e",
              }}
              id="right-button"
              onClick={() => handleButton("right")}
            >
              <BsArrowRightCircleFill size="25px" />
            </div>
          </div>
        </MediaQuery>
      </div>
      {collectionMenu()}
      {/* TODO:: onclick open modal with nfts from collection */}
      {/* {collection === null ? collectionMenu() : nftsMenu()} */}
    </div>
  );
};

export { NftShowroomComponent };
