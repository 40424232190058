import React, { useState } from "react";
import styles from "./nft-detail.module.css";
import modalStyles from "../modal/modal-styles.module.css";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alchemy, Network } from "alchemy-sdk";
import { BsArrowRightCircleFill, BsArrowLeftCircleFill } from "react-icons/bs";
import { RiCloseCircleFill } from "react-icons/ri";
import { MdCancel } from "react-icons/md";

import { ModalContent, openseaCollectionNames } from "../../constants";
import {
  add3Dots,
  getTimeDescription,
  uid,
} from "../../utils/image_placeholder";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import {
  setModalData,
  setModalContent,
  setModalOpen,
  setModalClose,
} from "../../redux/modalSlice";
import { ModalHistory } from "../modal/modal-history";
import MediaQuery from "react-responsive";
import { useMediaQuery } from "react-responsive";

import openSeaLogo from "../../assets/eye/opensea_pro.png";
import eyeWatchLogo from "../../assets/eye/eye_watch.png";
import blurLogo from "../../assets/eye/blur_logo.jpg";

export function NftDetailModal() {
  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API,
    network: Network.ETH_MAINNET,
  };

  const isDesktop = useMediaQuery({
    query: "(min-width: 700px)",
  });

  const dispatch = useDispatch();

  const alchemy = new Alchemy(settings);

  const [nft, setNft] = useState(null);
  const [activity, setActivity] = useState(null);
  const [isLeftButtonEnabledLocal, setIsLeftButtonEnabledLocal] = useState(
    ModalHistory.leftButtonEnabled
  );
  const [isRightButtonEnabledLocal, setIsRightButtonEnabledLocal] = useState(
    ModalHistory.rightButtonEnabled
  );
  useEffect(() => {
    setIsLeftButtonEnabledLocal(ModalHistory.leftButtonEnabled);
    setIsRightButtonEnabledLocal(ModalHistory.rightButtonEnabled);
  }, [ModalHistory.leftButtonEnabled, ModalHistory.rightButtonEnabled]);

  const modalData = useSelector((state: RootState) => state.modal.data);
  const [ownerAddressBackup, setOwnerAddressBackup] = useState<any>({
    address: "Loading...",
  });

  const ownersList = useSelector((state: RootState) => state.nft_owners.value);
  let { collectionId, tokenId, owner } = modalData;

  const options = {
    method: "GET",
    headers: {
      accept: "*/*",
      "x-api-key": process.env.REACT_APP_RESERVOIR_API_KEY,
    },
    ContentType: "application/json",
  };

  async function getNftActivity() {
    fetch(
      `https://api.reservoir.tools/tokens/${collectionId}%3A${tokenId}/activity/v5`,
      options
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        setActivity(response.activities);
      });
  }

  async function getNftDetails() {
    fetch(
      `https://api.reservoir.tools/tokens/v6?tokens=${collectionId}%3A${tokenId}&includeTopBid=true&includeAttributes=true&includeDynamicPricing=true&includeLastSale=true`,
      options
    )
      .then((response) => response.json())
      .then((nft) => {
        setNft(nft.tokens[0]);
        if (owner === null) {
          let newOwner = ownersList.find(
            (_owner) => _owner.address === nft.tokens[0].token.owner
          );
          if (!newOwner) {
            newOwner = {
              address: nft.tokens[0].token.owner,
              username: nft.tokens[0].token.owner,
              ens: null,
              last_visit: null,
              eyeverseRank: 9999,
              bio: "",
              twitterHandle: "",
              pfp: false,
            };
          }
          setOwnerAddressBackup(newOwner);
        }
      });
  }
  useEffect(() => {
    getNftDetails();
    getNftActivity();
  }, []);
  useEffect(() => {}, [nft]);
  //function returning value or dash if value is null
  const valueOrDash = (value: any) => {
    if (value) {
      return value;
    } else {
      return "-";
    }
  };

  const TopLabel = (top, bottom) => {
    return (
      <div className={styles["stats-label"]}>
        <label className={styles["top-stats"]}>{top}</label>
        <label className={styles["bottom-stats"]}>{bottom}</label>
      </div>
    );
  };
  //function returning a div with box style, containing two divs inside with classname stats-label
  const AttributeContainer = (type, value, rarity) => {
    return (
      <div className={styles["box"]} key={uid()}>
        <div
          className={isDesktop ? styles["stats-label"] : styles["stats-row"]}
        >
          <label
            className={isDesktop ? styles["top-stats"] : styles["left-stats"]}
          >
            {type}
          </label>
          <label
            className={
              isDesktop ? styles["bottom-stats"] : styles["right-stats"]
            }
          >
            {add3Dots(value, 22)}
          </label>
        </div>
        <div className={styles["stats-label"]}>
          <label
            className={isDesktop ? styles["top-stats"] : styles["left-stats"]}
          >
            Rarity
          </label>
          <label
            className={
              isDesktop ? styles["bottom-stats"] : styles["right-stats"]
            }
          >
            {rarity}
          </label>
        </div>
      </div>
    );
  };

  function generateRow(activity: any) {
    switch (activity.type) {
      case "sale":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Sale </div>
            <div className={styles["middle"]}> {activity?.token.tokenName}</div>
            <div className={styles["middle"]}>
              {activity?.price.amount.decimal}
            </div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );

      case "ask":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Ask </div>

            <div className={styles["middle"]}> {activity?.token.tokenName}</div>
            <div className={styles["middle"]}>
              {activity?.price.amount.decimal}
            </div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
      case "transfer":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Transfer </div>
            <div className={styles["middle"]}> {activity?.token.tokenName}</div>
            <div className={styles["middle"]}>-</div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
      case "mint":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Mint </div>
            <div className={styles["middle"]}> {activity?.token.tokenName}</div>
            <div className={styles["middle"]}>
              {activity?.price.amount.decimal}
            </div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
      case "bid":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Bid </div>
            <div className={styles["middle"]}> {activity?.token.tokenName}</div>
            <div className={styles["middle"]}>
              {activity?.price.amount.decimal}
            </div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
    }

    return (
      <div className={styles["row"]} key={uid()}>
        UNKNOWN ACTIVITY
      </div>
    );
  }

  return (
    <div className={styles["container"]}>
      <div className={modalStyles["modal-buttons-row"]}>
        <div
          className={
            ModalHistory.leftButtonEnabled
              ? modalStyles["left-nav-button"]
              : modalStyles["left-nav-button-disabled"]
          }
          id="left-button"
          onClick={() => ModalHistory.openPrevious(dispatch)}
        >
          <BsArrowLeftCircleFill size="25px" />
        </div>
        <div
          className={
            ModalHistory.rightButtonEnabled
              ? modalStyles["right-nav-button"]
              : modalStyles["right-nav-button-disabled"]
          }
          id="right-button"
          onClick={() => ModalHistory.openNext(dispatch)}
        >
          <BsArrowRightCircleFill size="25px" />
        </div>
        <div
          className={modalStyles["close-nav-button"]}
          id="right-button"
          onClick={() => {
            dispatch(setModalClose());
            // ModalHistory.openNext(dispatch)}
          }}
        >
          <RiCloseCircleFill size="30px" />
        </div>
      </div>
      <MediaQuery maxWidth={700}>
        <div className={styles["mobile-center"]}>
          <div className={styles["nft-title"]}>{nft?.token?.name}</div>
          <img
            className={styles["image"]}
            width="100%"
            height="100%"
            src={nft?.token?.image}
            alt="nft image"
          />
          <div className={styles["left-section"]}>
            <div className={styles["labels"]}>
              {/* <div className={styles["top-stats"]}>
              {TopLabel(
                "Price",
                valueOrDash(nft?.market?.floorAsk?.price?.amount.decimal)
              )}
            </div> */}
              <div className={styles["top-stats"]}>
                {TopLabel(
                  "Last Sale",
                  valueOrDash(nft?.token?.lastSale?.price?.amount.decimal)
                )}
              </div>
              <div className={styles["top-stats"]}>
                {TopLabel(
                  "Top Bid",
                  valueOrDash(nft?.market?.topBid?.price?.amount.decimal)
                )}
              </div>
              <div className={styles["top-stats"]}>
                {TopLabel("Rarity", valueOrDash(nft?.token?.rarityRank))}
              </div>
              <div className={styles["top-stats"]}>
                {TopLabel(
                  "Floor",
                  valueOrDash(nft?.token?.lastSale?.price?.amount.decimal)
                )}
              </div>
            </div>
          </div>

          <div className={styles["bottom-column"]}>
            <label
              className={styles["owner-label"]}
              onClick={() => {
                let _owner = owner;
                if (owner?.address === undefined) {
                  _owner = ownerAddressBackup;
                }
                dispatch(
                  setModalData({
                    owner: _owner,
                  })
                );
                dispatch(setModalContent(ModalContent.USER_PROFILE));
                dispatch(setModalOpen());
                ModalHistory.push({
                  data: {
                    owner: _owner,
                  },
                  content: ModalContent.USER_PROFILE,
                  isOpen: true,
                });
              }}
            >
              Owner:{" "}
              {add3Dots(
                owner?.username ??
                  owner?.address ??
                  ownerAddressBackup?.address ??
                  "Loading",
                25
              )}
            </label>

            <div className={styles["button-row"]}>
              <a
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                target="_blank"
                rel="noreferrer"
                href={`https://www.eye.watch/collection/ethereum/${collectionId}/${tokenId}`}
              >
                <img
                  height="24px"
                  alt="eye watch logo"
                  src={eyeWatchLogo}
                ></img>
              </a>
              <a
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                target="_blank"
                rel="noreferrer"
                href={`https://blur.io/asset/${collectionId}/${tokenId}`}
              >
                <img height="30px" alt="blur logo" src={blurLogo}></img>
              </a>
              <a
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                target="_blank"
                rel="noreferrer"
                href={`https://opensea.io/assets/ethereum/${collectionId}/${tokenId}`}
              >
                <img height="24px" alt="opensea logo" src={openSeaLogo}></img>
              </a>
            </div>
          </div>
          <div className={styles["right-side"]}>
            <div className={styles["section"]} key={uid()}>
              <div className={styles["flex-container"]}>
                {nft?.token?.attributes.map((attribute) =>
                  AttributeContainer(
                    attribute.key,
                    attribute.value,
                    attribute.tokenCount
                  )
                )}
              </div>
            </div>
            <div className={styles["section-bottom"]}>
              <h2>Transaction History</h2>
              <div className={styles["row"]} key={uid()}>
                <div className={styles["header"]}>Type </div>
                <div className={styles["header"]}>Name </div>
                <div className={styles["header"]}>Price </div>
                <div className={styles["header"]}>Time </div>
              </div>
              <div className={styles["scrollable"]}>
                <ul>{activity?.map(generateRow)}</ul>
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={700}>
        <div className={styles["left-side"]}>
          <div className={styles["left-label"]}>
            <div className={styles["nft-title"]}>{nft?.token?.name}</div>
          </div>
          <div className={styles["left-section"]}>
            <div className={styles["labels"]}>
              {/* <div className={styles["top-stats"]}>
              {TopLabel(
                "Price",
                valueOrDash(nft?.market?.floorAsk?.price?.amount.decimal)
              )}
            </div> */}
              <div className={styles["top-stats"]}>
                {TopLabel(
                  "Last Sale",
                  valueOrDash(nft?.token?.lastSale?.price?.amount.decimal)
                )}
              </div>
              <div className={styles["top-stats"]}>
                {TopLabel(
                  "Top Bid",
                  valueOrDash(nft?.market?.topBid?.price?.amount.decimal)
                )}
              </div>
              <div className={styles["top-stats"]}>
                {TopLabel("Rarity", valueOrDash(nft?.token?.rarityRank))}
              </div>
              <div className={styles["top-stats"]}>
                {TopLabel(
                  "Floor",
                  valueOrDash(nft?.token?.lastSale?.price?.amount.decimal)
                )}
              </div>
            </div>
          </div>
          <div className={styles["image"]}>
            <img
              width="320px"
              height="320px"
              src={nft?.token?.image}
              alt="nft image"
            />
          </div>

          {/* <div className={styles["polaroid"]}> */}
          {/* <img src={nft?.token?.image} alt="nftImage" /> */}
          <div className={styles["bottom-row"]}>
            <div className={styles["bottom-column"]}>
              <label
                className={styles["owner-label"]}
                onClick={() => {
                  let _owner = owner;
                  if (owner?.address === undefined) {
                    _owner = ownerAddressBackup;
                  }
                  dispatch(
                    setModalData({
                      owner: _owner,
                    })
                  );
                  dispatch(setModalContent(ModalContent.USER_PROFILE));
                  dispatch(setModalOpen());
                  ModalHistory.push({
                    data: {
                      owner: _owner,
                    },
                    content: ModalContent.USER_PROFILE,
                    isOpen: true,
                  });
                }}
              >
                Owner:{" "}
                {add3Dots(
                  owner?.username ??
                    owner?.address ??
                    ownerAddressBackup?.address ??
                    "Loading",
                  25
                )}
              </label>

              <div className={styles["button-row"]}>
                <a
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.eye.watch/collection/ethereum/${collectionId}/${tokenId}`}
                >
                  <img
                    height="24px"
                    alt="eye watch logo"
                    src={eyeWatchLogo}
                  ></img>
                </a>
                <a
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={`https://blur.io/asset/${collectionId}/${tokenId}`}
                >
                  <img height="30px" alt="blur logo" src={blurLogo}></img>
                </a>
                <a
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={`https://pro.opensea.io/collection/${openseaCollectionNames[collectionId]}?view=${tokenId}&tokenAddress=${collectionId}`}
                >
                  <img height="24px" alt="opensea logo" src={openSeaLogo}></img>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles["right-side"]}>
          <div className={styles["section"]} key={uid()}>
            <div className={styles["flex-container"]}>
              {nft?.token?.attributes.map((attribute) =>
                AttributeContainer(
                  attribute.key,
                  attribute.value,
                  attribute.tokenCount
                )
              )}
            </div>
          </div>
          <div className={styles["section-bottom"]}>
            <h2>Transaction History</h2>
            <div className={styles["row"]} key={uid()}>
              <div className={styles["header"]}>Type </div>
              <div className={styles["header"]}>Name </div>
              <div className={styles["header"]}>Price </div>
              <div className={styles["header"]}>Time </div>
            </div>
            <div className={styles["scrollable"]}>
              <ul>{activity?.map(generateRow)}</ul>
            </div>
          </div>
        </div>
      </MediaQuery>
    </div>
  );
}
