import React from "react";
import styles from "./page-menu-component.module.css";
import {
  IoGameController,
  IoHomeSharp,
  IoAccessibilitySharp,
  IoSettingsSharp,
  IoBasketSharp,
  IoConstructSharp,
} from "react-icons/io5";
import { Tile } from "./tile-component";
import { useDispatch } from "react-redux";
import {
  setModalContent,
  setModalData,
  setModalOpen,
} from "../../../../redux/modalSlice";
import { useAccount } from "wagmi";
import { ModalContent } from "../../../../constants";

const PageMenuComponent: React.FC = () => {
  const { address } = useAccount();
  let dispatch = useDispatch();
  const activeTileName = "Home";
  const size = "34px";
  return (
    <div className={styles["container-with-button"]}>
      <div className={styles["container-tiles"]}>
        <Tile
          icon={<IoHomeSharp size={size} />}
          iconLabel={"Home"}
          activeTileName={activeTileName}
        ></Tile>
        <Tile
          icon={<IoAccessibilitySharp size={size} />}
          iconLabel={"Community"}
          activeTileName={activeTileName}
        ></Tile>
        <Tile
          icon={<IoGameController size={size} />}
          iconLabel={"Games"}
          activeTileName={activeTileName}
        ></Tile>
        <Tile
          icon={<IoBasketSharp size={size} />}
          iconLabel={"Market"}
          activeTileName={activeTileName}
        ></Tile>
        <Tile
          icon={<IoConstructSharp size={size} />}
          iconLabel={"Tools"}
          activeTileName={activeTileName}
        ></Tile>
        <Tile
          icon={<IoSettingsSharp size={size} />}
          iconLabel={"Settings"}
          activeTileName={activeTileName}
        ></Tile>
      </div>
      {/* <button
        className={styles["profile-button"]}
        onClick={() => {
          dispatch(setModalData({ owner: { address: address } }));
          dispatch(setModalContent(ModalContent.USER_SETTINGS));
          dispatch(setModalOpen());
        }}
      >
        {" "}
        PROFILE
      </button> */}
    </div>
  );
};

export { PageMenuComponent };
