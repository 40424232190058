import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { owner } from "../constants";

export interface AccountState {
  address: string;
  owner: owner;
}

const initialState: AccountState = {
  address: "",
  owner: null,
};

export const userSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setCurrentOwner: (state, action: PayloadAction<owner>) => {
      state.owner = action.payload;
    },
    setAccount: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    removeAccount: (state) => {
      state.address = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAccount, removeAccount, setCurrentOwner } = userSlice.actions;

export default userSlice.reducer;
