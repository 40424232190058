import "./App.css";
import "./assets/fonts/Oxanium-VariableFont_wght.ttf";
import { Dashboard } from "./pages/dashboard/dashboard";
// Web3, wagmi imports
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon } from "wagmi/chains";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { UserProfile } from "./pages/user-profile/user-profile";
import Header from "./pages/header/header";
import styles from "./pages/dashboard/dashboard.module.css";
import { NftDetailPage } from "./pages/nft-detail/nft-detail";
import { MainModal } from "./pages/modal/modal";
import { SideMenu } from "./pages/mobile-side-menu/mobile-side-menu";
// import * as dotenv from "dotenv";
// dotenv.config();
// import express from "express";
const chains = [arbitrum, mainnet, polygon];
const projectId = process.env.REACT_APP_W3M_PROJECT_ID;

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/nft/:collectionId/:tokenId",
    element: <NftDetailPage />,
  },
]);

const ethereumClient = new EthereumClient(wagmiClient, chains);
function App() {
  return (
    <div className="App">
      <div className={styles["container-header"]}>
        <Header></Header>
        <SideMenu />
        <WagmiConfig client={wagmiClient}>
          {/* <Dashboard /> */}
          <RouterProvider router={router} />
        </WagmiConfig>
        <MainModal />
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      </div>
    </div>
  );
}

export default App;
