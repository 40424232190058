import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nft } from "alchemy-sdk";

export interface NFTListState {
  value: Nft[];
}

const initialState: NFTListState = {
  value: [],
};

export const nftSlice = createSlice({
  name: "nfts",
  initialState,
  reducers: {
    addAll: (state, action: PayloadAction<Nft[]>) => {
      state.value = action.payload;
    },
    addNew: (state, action: PayloadAction<Nft[]>) => {
      const seen = new Set();

      let newNfts = action.payload.filter((nft) => {
        if (seen.has(nft.tokenId)) {
          return false;
        } else {
          seen.add(nft.tokenId);
          return true;
        }
      });
      state.value.push(...newNfts);
    },
  },
});

// Action creators are generated for each case reducer function
export const { addAll, addNew } = nftSlice.actions;

export default nftSlice.reducer;
