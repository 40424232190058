import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { owner } from "../constants";

export interface NftOwnersListState {
  value: owner[];
}

const initialState: NftOwnersListState = {
  value: [],
};

export const nftOwnersSlice = createSlice({
  name: "nft_owners",
  initialState,
  reducers: {
    addAllOwners: (state, action: PayloadAction<owner[]>) => {
      state.value = action.payload;
    },
    changeAddress: (
      state,
      action: PayloadAction<{ address: string; ens: string }>
    ) => {
      // let index = state.value.indexOf(action.payload.address);
      // state.value[index] = action.payload.ens;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addAllOwners } = nftOwnersSlice.actions;

export default nftOwnersSlice.reducer;
