import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./nft-showroom.module.css";
import modalStyles from "../../modal/modal-styles.module.css";
import { NftCollectionTile } from "./nft-collection-tile";
import { uid } from "../../../utils/image_placeholder";
import { BsArrowRightCircleFill, BsArrowLeftCircleFill } from "react-icons/bs";
import {
  EYEVERSE_RESERVOIR_COLLECTION_SET,
  REINCARNATED_CONTRACT_ADDRESS,
  owner,
} from "../../../constants";
import CircleLoader from "react-spinners/CircleLoader";
import { getNumberOfStakedTokens } from "../../../utils/universal_functions";
import { ModalHistory } from "../../modal/modal-history";
import { RiCloseCircleFill } from "react-icons/ri";
import { setModalClose } from "../../../redux/modalSlice";

type props = {
  owner: owner;
  setValue: (value: number) => void;
};
const NftShowroomComponent: React.FC<props> = (props) => {
  let dispatch = useDispatch();
  let totalValue = 0;
  const { owner, setValue } = props;
  const options = {
    method: "GET",
    headers: {
      accept: "*/*",
      "x-api-key": process.env.REACT_APP_RESERVOIR_API_KEY,
    },
    ContentType: "application/json",
  };
  const [nftContracts, setNftContracts] = useState([]);
  const [chosenCollection, setChosenCollection] = useState(null);
  let nftsArray = [];
  const collectionMenu = () => (
    <div className={styles["nft-collection-container"]}>
      {nftContracts?.map((contract) => (
        <NftCollectionTile
          contract={contract}
          setCollection={setChosenCollection}
          key={uid()}
          owner={owner}
        />
      ))}
    </div>
  );
  useEffect(() => {
    getNftCollections();
  }, []);

  function getRemainingCollections() {
    fetch(
      `https://api.reservoir.tools/collections/v5?collectionsSetId=${EYEVERSE_RESERVOIR_COLLECTION_SET}`,
      options
    )
      .then((response) => response.json())
      .then(async (response: any) => {
        //get ids of owner collections to not overwrite them
        let collectionsIds = nftsArray.map(
          (collection) => collection.collection.id
        );
        //filter to only those collections which are not in  and add them to the store
        response.collections
          .filter(({ id }) => !collectionsIds.includes(id))
          .forEach((collection) => {
            nftsArray.push({
              collection: collection,
              ownership: {
                tokenCount: "0",
                onSaleCount: "0",
              },
            });
          });

        // setNftContracts(nftsArray);
        //get staked nfts number
        let stakedNftsNumber = await getNumberOfStakedTokens(owner.address);
        //filter to reincarnated collection and add staked nfts number to it
        let reincarnatedIndex = nftsArray.findIndex((collection) => {
          return collection.collection.id === REINCARNATED_CONTRACT_ADDRESS;
        });
        let newCollectionObject = nftsArray[reincarnatedIndex];
        newCollectionObject.ownership.tokenCount = (
          Number(newCollectionObject.ownership.tokenCount) + stakedNftsNumber
        ).toString();
        let stakedValue =
          stakedNftsNumber *
          nftsArray[reincarnatedIndex].collection.floorSale["1day"];
        setValue(totalValue + stakedValue);
        nftsArray[reincarnatedIndex] = newCollectionObject;
        setNftContracts([...nftsArray]);
      });
  }

  function getNftCollections() {
    fetch(
      `https://api.reservoir.tools/users/${owner.address}/collections/v3/?collectionsSetId=${EYEVERSE_RESERVOIR_COLLECTION_SET}`,
      options
    )
      .then((response) => response.json())
      .then((res: any) => {
        const filteredResponse = res.collections;
        setNftContracts(nftContracts.concat(filteredResponse));
        nftsArray = filteredResponse;
        let value = 0;
        filteredResponse?.forEach((response) => {
          let newValue =
            Number(response.ownership.tokenCount) *
            response.collection.floorSale["1day"];
          if (newValue > 0) {
            value += newValue;
          }
        });
        totalValue = value;
        setValue(value);
        getRemainingCollections();

        // calculateCollectionValue(nfts, filteredResponse);
        return filteredResponse;
        // filteredResponse.forEach(fetchImage);
      })
      .catch((err) => console.error(err));
  }

  return (
    <div>
      <div className={styles["top-row"]}>
        <div className={modalStyles["modal-buttons-row"]}>
          <div
            className={
              ModalHistory.leftButtonEnabled
                ? modalStyles["left-nav-button"]
                : modalStyles["left-nav-button-disabled"]
            }
            id="left-button"
            onClick={() => ModalHistory.openPrevious(dispatch)}
          >
            <BsArrowLeftCircleFill size="25px" />
          </div>
          <div
            className={
              ModalHistory.rightButtonEnabled
                ? modalStyles["right-nav-button"]
                : modalStyles["right-nav-button-disabled"]
            }
            id="right-button"
            onClick={() => ModalHistory.openNext(dispatch)}
          >
            <BsArrowRightCircleFill size="25px" />
          </div>
          <div
            className={modalStyles["close-nav-button"]}
            id="right-button"
            onClick={() => {
              dispatch(setModalClose());
              // ModalHistory.openNext(dispatch)}
            }}
          >
            <RiCloseCircleFill size="30px" />
          </div>
        </div>
        <div className={styles["title-label"]}>
          {/* <div style={{ color: "#00D56E", paddingRight: "10px" }}>My </div> */}
          <div style={{ color: "#FFFFFF" }}>Assets</div>
        </div>
      </div>
      <div>
        {
          <CircleLoader
            size="30px"
            color="#36d7b7"
            loading={nftContracts === null}
          />
        }
      </div>
      {collectionMenu()}
    </div>
  );
};

export { NftShowroomComponent };
