import { paths } from "@reservoir0x/reservoir-sdk";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  ModalContent,
  Transaction,
} from "../../../../constants";
import { setHideCollections } from "../../../../redux/helperSlice";
import {
  addTransactions,
  setTransactions,
} from "../../../../redux/transactionsSlice";
import { useNavigate } from "react-router-dom";

import {
  add3Dots,
  collectionLookup,
  getTimeDescription,
  ImageFromUrl,
  uid,
} from "../../../../utils/image_placeholder";
import { MultiSelect } from "react-multi-select-component";

import styles from "./transactions-component.module.css";
import { BsArrowUpSquareFill } from "react-icons/bs";
import {
  setModalContent,
  setModalData,
  setModalOpen,
} from "../../../../redux/modalSlice";
import { ModalHistory } from "../../../modal/modal-history";

const TransactionsComponent: React.FC = () => {
  const navigate = useNavigate();
  let contracts = useSelector(
    (state: RootState) => state.nfts_collections.value
  );
  let isTransactionsVisible = useSelector(
    (state: RootState) => state.helper.chosenCollection === null
  );
  let transactionData = useSelector(
    (state: RootState) => state.transactions.value
  );
  let dispatch = useDispatch();
  const [selected, setSelected] = useState([{ label: "Sale", value: "sale" }]);

  useEffect(() => {
    if (contracts.length === 0) return;
    const options = {
      method: "GET",
      headers: { accept: "*/*", "x-api-key": process.env.REACT_APP_RESERVOIR_API_KEY },
      ContentType: "application/json",
    };
    dispatch(setTransactions([]));
    contracts.forEach((contract) => {
      let contractsString = "collection=";
      contractsString = contractsString + contract.collection.primaryContract;
      let transactionDecoded: any;
      let transactionsTypes = "";
      selected.forEach((type) => {
        transactionsTypes += "types=" + type.value + "&";
      });
      transactionsTypes = transactionsTypes.slice(0, -1);
      fetch(
        `https://api.reservoir.tools/collections/activity/v5?${contractsString}&includeMetadata=true&limit=25&${transactionsTypes}`,
        options
      )
        .then((response) => response.json())
        .then((res: any) => {
          const response =
            res as paths["/collections/activity/v5"]["get"]["responses"]["200"]["schema"];
          transactionDecoded = response.activities.map((event) => ({
            price: event.price,
            collectionImage: event.collection.collectionImage,
            type: event.type,
            date: event.timestamp,
            collection: event.contract,
            nftId: event.token.tokenId,
            nftImage: event.token.tokenImage,
            to: event.toAddress ?? "",
          }));
          dispatch(addTransactions(transactionDecoded));
        })
        .catch((err) => console.error(err));
    });
  }, [contracts, selected]);

  const selectOptions = [
    { label: "Sale", value: "sale" },
    { label: "Transfer", value: "transfer" },
    { label: "Mint", value: "mint" },
    { label: "Ask", value: "ask" },
    { label: "Bid", value: "bid" },
  ];
  // TODO: create different rows for each transactions
  const createRow = (transaction: Transaction) => {
    return (
      <div className={styles["row"]} key={uid()}>
        <div className={styles["date-col"]}>
          <div>{getTimeDescription(transaction.date)}</div>
        </div>

        <div className={styles["sale-type"]}>
          {transaction.type.toUpperCase()}
        </div>
        <div className={styles["collection"]}>
          {collectionLookup(transaction.collection)}
        </div>
        <div className={styles["sale-to"]}>
          <ImageFromUrl
            url={transaction.nftImage}
            onClick={() =>
              openNftDetails(null, transaction.collection, transaction.nftId)
            }
          />

          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              openNftDetails(null, transaction.collection, transaction.nftId)
            }
          >
            {" #" + transaction.nftId}
          </div>
          <div style={{ color: "grey" }}>to</div>
          {/* TODO:: open user details instead of nft details */}
          <div
            onClick={() =>
              openNftDetails(null, transaction.collection, transaction.nftId)
            }
          >
            {add3Dots(transaction.to, 20)}
          </div>
        </div>
        <div className={styles["price-col"]}>
          <div>{transaction.price.toString()} ETH</div>
        </div>
      </div>
    );
  };

  const generateRows = () => {
    if (isTransactionsVisible) {
      return (
        <div className={styles["transactions-table"]}>
          {transactionData.map((sale) => createRow(sale))}
        </div>
      );
    }
    return <></>;
  };

  function openNftDetails(owner, collectionId, tokenId) {
    dispatch(setModalOpen());
    dispatch(
      setModalData({
        owner: owner,
        collectionId: collectionId,
        tokenId: tokenId,
      })
    );
    dispatch(setModalContent(ModalContent.NFT_DETAILS));
    ModalHistory.push({
      data: { owner: owner, collectionId: collectionId, tokenId: tokenId },
      content: ModalContent.NFT_DETAILS,
      isOpen: true,
    });
  }

  return (
    <div onClick={() => dispatch(setHideCollections())}>
      <div className={styles["filter-row"]}>
        <div className={styles["title-label"]}>
          {isTransactionsVisible ? null : (
            <div style={{ color: "#00D56E", paddingRight: "10px" }}>
              <BsArrowUpSquareFill />
            </div>
          )}
          <div style={{ color: "#00D56E", paddingRight: "10px" }}>Market</div>
          <div style={{ color: "#FFFFFF" }}>Activity</div>
        </div>
        <MultiSelect
          className={styles["multiselect-custom"]}
          options={selectOptions}
          value={selected}
          onChange={setSelected}
          labelledBy="Transactions Filter"
        />
      </div>
      <div className={styles["transactions-component"]}>{generateRows()}</div>
    </div>
  );
};

export { TransactionsComponent };
