import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { NftTile } from "./nft-tile";
import styles from "./nft-showroom.module.css";
import modalStyles from "../modal/modal-styles.module.css";
import { add3Dots, collectionLookup, uid } from "../../utils/image_placeholder";
import { BsArrowRightCircleFill, BsArrowLeftCircleFill } from "react-icons/bs";
import { ModalContent, owner } from "../../constants";
import {
  setModalClose,
  setModalContent,
  setModalData,
} from "../../redux/modalSlice";
import { ModalHistory } from "../modal/modal-history";
import {
  fetchNFTs,
  getStakedTokensForAccount,
} from "../../utils/universal_functions";
import CircleLoader from "react-spinners/CircleLoader";
import { RiCloseCircleFill } from "react-icons/ri";
import { useAccount } from "wagmi";

export const NftShowroomComponent: React.FC = () => {
  const dispatch = useDispatch();
  const modalData = useSelector((state: RootState) => state.modal.data);
  const { owner, nftCollection } = modalData;
  const currentOwnerNfts = useSelector((state: RootState) => state.nfts.value);
  const currentOwner = useSelector((state: RootState) => state.user.owner);
  const [nftsData, setNftsData] = useState(null);

  useEffect(() => {
    if (owner && owner?.address) {
      if (
        owner.address === currentOwner.address &&
        currentOwnerNfts.length > 0
      ) {
        setNftsData(currentOwnerNfts);
      } else {
        fetchNFTs(owner?.address).then((nfts) => {
          setNftsData(nfts);
          getStakedTokensForAccount(owner.address).then((stakedTokens) => {
            setNftsData(nfts.concat(stakedTokens));
          });
        });
      }
    }
  }, [owner]);

  const nftsMenu = () => {
    return nftsData?.length > 0 ? (
      <div className={styles["nft-container"]}>
        {nftsData
          ?.filter(
            (nft) =>
              nft.contract.address ===
              nftCollection?.collection?.primaryContract
          )
          .map((nft) => (
            <NftTile nft={nft} owner={owner} key={uid()} />
          ))}
      </div>
    ) : (
      <CircleLoader size="200px" color="#36d7b7" loading={!nftsData?.length} />
    );
  };
  function openOwnerModal() {
    dispatch(setModalData({ owner: owner }));
    dispatch(setModalContent(ModalContent.USER_PROFILE));
    ModalHistory.push({
      data: { owner: owner },
      content: ModalContent.USER_PROFILE,
      isOpen: true,
    });
  }
  return (
    <div className={styles["nft-collection-container"]}>
      <div className={styles["top-row"]}>
        <div className={modalStyles["modal-buttons-row"]}>
          <div
            className={
              ModalHistory.leftButtonEnabled
                ? modalStyles["left-nav-button"]
                : modalStyles["left-nav-button-disabled"]
            }
            id="left-button"
            onClick={() => ModalHistory.openPrevious(dispatch)}
          >
            <BsArrowLeftCircleFill size="25px" />
          </div>
          <div
            className={
              ModalHistory.rightButtonEnabled
                ? modalStyles["right-nav-button"]
                : modalStyles["right-nav-button-disabled"]
            }
            id="right-button"
            onClick={() => ModalHistory.openNext(dispatch)}
          >
            <BsArrowRightCircleFill size="25px" />
          </div>
          <div
            className={modalStyles["close-nav-button"]}
            id="right-button"
            onClick={() => {
              dispatch(setModalClose());
              // ModalHistory.openNext(dispatch)}
            }}
          >
            <RiCloseCircleFill size="30px" />
          </div>
        </div>
        <div className={styles["title-label"]}>
          {/* <div style={{ color: "#FFFFFF", paddingRight: "10px" }}>Owner: </div> */}
          <div className={styles["owner-label"]} onClick={openOwnerModal}>
            {add3Dots(
              owner?.username
                ? owner.username
                : owner?.ens
                ? owner.ens
                : owner?.address,
              20
            )}
          </div>
        </div>
        <div className={styles["title-label"]}>
          {/* <div style={{ color: "#00D56E", paddingRight: "10px" }}>
            Collection:
          </div> */}
          <div
            style={{
              color: "#00D56E",
              borderBottomWidth: "1px",
              width: "95%",
            }}
          >
            {collectionLookup(nftCollection?.collection?.primaryContract)}
          </div>
        </div>
      </div>
      {nftsMenu()}
    </div>
  );
};
