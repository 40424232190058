import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import styles from "./community.module.css";
import {
  ImagePlaceHolder,
  add3Dots,
  uid,
} from "../../../../utils/image_placeholder";
import { ethers } from "ethers";
import { addAllOwners } from "../../../../redux/nftOwnersSlice";
import {
  setModalOpen,
  setModalData,
  setModalContent,
} from "../../../../redux/modalSlice";
import { ModalHistory } from "../../../modal/modal-history";
import { ModalContent, owner } from "../../../../constants";
import { handleSideMenu } from "../../../../redux/helperSlice";

type props = {};

const CommunityListComponent: React.FC<props> = () => {
  let nftOwners = useSelector((state: RootState) => state.nft_owners.value);
  let isSideMenuOpen = useSelector(
    (state: RootState) => state.helper.sideMenuOpen
  );
  let dispatch = useDispatch();

  function openModalForUser(user: owner) {
    dispatch(setModalData({ owner: user }));
    dispatch(setModalContent(ModalContent.USER_PROFILE));
    dispatch(setModalOpen());
    if (isSideMenuOpen) dispatch(handleSideMenu());
    ModalHistory.push({
      data: { owner: user },
      content: ModalContent.USER_PROFILE,
      isOpen: true,
    });
  }

  return (
    <div>
      <div className={styles["title-label"]}>
        <div style={{ color: "#00D56E" }}>Community</div>
      </div>
      <div className={styles["community-container"]}>
        {nftOwners.map((owner) => {
          let color = "white";
          if (owner?.username || owner?.pfp === true) color = "#00be60";
          return (
            <div
              onClick={() => openModalForUser(owner)}
              className={styles["community-row"]}
              key={uid()}
            >
              {/* <ImagePlaceHolder /> */}
              <label style={{ cursor: "pointer", color: color }}>
                {add3Dots(owner?.username ?? owner?.ens ?? owner.address, 30)}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export { CommunityListComponent };
