import cx from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { RootState } from "../../app/store";
import { CommunityListComponent } from "./dashboard-pages/community/community-list";
import { SalesPriceChart } from "./dashboard-pages/graphs/price_chart";
import { NftShowroomComponent } from "./dashboard-pages/nft_showroom/nft-showroom-component";
import { PageMenuComponent } from "./dashboard-pages/page-menu/page-menu-component";
import { TransactionsComponent } from "./dashboard-pages/transactions/transactions-component";
import { UserActivityComponent } from "./dashboard-pages/user-activity/user-activity-component";
import Header from "../header/header";
import styles from "./dashboard.module.css";
import { useMediaQuery } from "react-responsive";
import MediaQuery from "react-responsive";

type props = {};
const Dashboard: React.FC<props> = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 700px)",
  });

  const { isConnected } = useAccount();
  let chosenCollection = useSelector(
    (state: RootState) => state.helper.chosenCollection
  );

  return (
    <div>
      {/* <Header></Header> */}
      {isConnected ? (
        <div className={styles["container-columns"]}>
          <MediaQuery minWidth={700}>
            <div className={styles["column-left"]}>
              <div className={styles["row-left-top"]}>
                <PageMenuComponent></PageMenuComponent>
              </div>
              <div className={styles["row-left-bottom"]}>
                <CommunityListComponent></CommunityListComponent>
              </div>
            </div>
          </MediaQuery>

          {/* if a collection is chosen we add a styling to css. It should move the bottom page down and increase size of upper page */}
          <div className={styles["column-center"]}>
            <div
              className={cx(
                styles["row-center-top"],
                chosenCollection === null ? "" : styles["row-center-top-active"]
              )}
            >
              <NftShowroomComponent></NftShowroomComponent>
            </div>
            <div
              className={cx(
                styles["row-center-bottom"],
                chosenCollection === null
                  ? ""
                  : styles["row-center-bottom-active"]
              )}
            >
              <MediaQuery maxWidth={700}>
                <UserActivityComponent />
              </MediaQuery>
              <MediaQuery minWidth={701}>
                <TransactionsComponent />
              </MediaQuery>
            </div>
          </div>
          <MediaQuery minWidth={700}>
            <div className={styles["column-right"]}>
              <div className={styles["row-right-top"]}>
                <UserActivityComponent />{" "}
              </div>
              <div className={styles["row-right-bottom"]}>
                <SalesPriceChart />
              </div>
            </div>
          </MediaQuery>
        </div>
      ) : (
        <div
          style={{
            color: "white",
            height: "50px",
            alignContent: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          Your wallet is not connected
        </div>
      )}
    </div>
  );
};
export { Dashboard };
