import { Nft, OwnedNft } from "alchemy-sdk";
import React, { useEffect, useState } from "react";
import CircleLoader from "react-spinners/CircleLoader";
import LazyLoad from "react-lazy-load";
import styles from "./nft-showroom.module.css";
import {
  setModalContent,
  setModalData,
  setModalOpen,
} from "../../redux/modalSlice";
import { ModalHistory } from "../modal/modal-history";

import { ModalContent, owner } from "../../constants";
import { useDispatch } from "react-redux";
import phoenix from "../../assets/images/phoenix_logo.webp";
import { add3Dots } from "../../utils/image_placeholder";
type tileProps = {
  nft: any;
  owner: owner;
};

const NftTile: React.FC<tileProps> = ({ nft, owner }) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [img, setImg] = useState("");
  const [loading, setLoading] = useState(true);
  const fetchImage = async () => {
    let url = nft.media[0].thumbnail;
    if (url.indexOf("ipfs://") != -1) {
      url = "https://ipfs.io/" + url.slice(7);
    }
    setImg(url);
  };
  useEffect(() => {
    fetchImage();
  }, []);

  function openNftDetails() {
    dispatch(setModalOpen());
    dispatch(
      setModalData({
        owner: owner,
        collectionId: nft.contract.address,
        tokenId: nft.tokenId,
      })
    );
    dispatch(setModalContent(ModalContent.NFT_DETAILS));
    ModalHistory.push({
      data: {
        owner: owner,
        collectionId: nft.contract.address,
        tokenId: nft.tokenId,
      },
      content: ModalContent.NFT_DETAILS,
      isOpen: true,
    });
  }

  return (
    <>
      <div className={styles["nft-tile"]} onClick={() => openNftDetails()}>
        <div style={{ display: loading ? "block" : "none" }}>
          {<CircleLoader size="50px" color="#36d7b7" loading={loading} />}
        </div>
        <LazyLoad offset={300}>
          <img
            className={styles["nft-tile-image"]}
            src={img}
            alt="icons"
            onLoad={() => setLoading(false)}
          />
        </LazyLoad>
        <div className={styles["phoenix-img"]}>
          {nft?.isStaked ? <img alt="staked icon" src={phoenix} /> : null}
        </div>
        {/* TODO:: make this font smaller on mobile */}
        <label className={styles["nft-title-label"]}>
          {add3Dots(nft.title, 35)}
        </label>
      </div>
    </>
  );
};

export { NftTile };
