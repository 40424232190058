import { paths } from "@reservoir0x/reservoir-sdk";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { RootState } from "../../../../app/store";
import { EYEVERSE_CONTRACTS_ARRAY, ModalContent } from "../../../../constants";
import { setSpentOnCollection } from "../../../../redux/helperSlice";
import {
  collectionLookup,
  getTimeDescription,
  ImageFromUrl,
  uid,
} from "../../../../utils/image_placeholder";
import { useNavigate } from "react-router-dom";

import styles from "./user-activity-component.module.css";
import {
  setModalOpen,
  setModalData,
  setModalContent,
} from "../../../../redux/modalSlice";
import { ModalHistory } from "../../../modal/modal-history";
import MediaQuery from "react-responsive";

export const UserActivityComponent: React.FC = () => {
  const navigate = useNavigate();

  let dispatch = useDispatch();
  const { address, isConnected } = useAccount();
  // const address = brownbear_eth;
  const [userActivity, setUserActivity] = useState([]);
  let spent = useSelector((state: RootState) => state.helper.spentOnCollection);
  const USER_ACTIVITY_LIMIT = 60;
  let contracts = useSelector(
    (state: RootState) => state.nfts_collections.value
  );
  function calculateSpent() {
    let sum = 0;
    userActivity.forEach((act) => {
      if (
        act.type == "sale" &&
        (act.toAddress as String).toLowerCase() === address.toLowerCase()
      ) {
        sum = sum + act.price;
      }
    });
    if (spent < sum) dispatch(setSpentOnCollection(sum));
    return sum;
  }

  async function getUserActivity() {
    const options = {
      method: "GET",
      headers: {
        accept: "*/*",
        "x-api-key": process.env.REACT_APP_RESERVOIR_API_KEY,
      },
      ContentType: "application/json",
    };
    let userActivityArray = [];
    let continuationString = "";
    let collectionsString = "";
    EYEVERSE_CONTRACTS_ARRAY.forEach((collection) => {
      collectionsString += `&collection=${collection}&`;
    });

    while (
      userActivityArray.length < USER_ACTIVITY_LIMIT &&
      continuationString != null
    ) {
      //first fetch
      let fetchString = "";
      if (continuationString === "") {
        fetchString = `https://api.reservoir.tools/users/activity/v5?users=${address}&limit=20&${collectionsString}includeMetadata=true`;
      } else {
        //fetch with continuation
        fetchString = `https://api.reservoir.tools/users/activity/v5?users=${address}&limit=20&${collectionsString}includeMetadata=true&continuation=${continuationString}`;
      }
      await fetch(fetchString, options)
        .then((response) => response.json())
        .then((res: any) => {
          const response =
            res as paths["/users/activity/v5"]["get"]["responses"]["200"]["schema"];
          // filter to only activity in eyeverse collections
          continuationString = response.continuation;
          let responseDecoded = response.activities.map((act) => ({
            type: act.type,
            price: act.price,
            collectionId: act.collection.collectionId,
            timestamp: act.timestamp,
            tokenName:
              collectionLookup(act.collection.collectionId) +
              " #" +
              act.token?.tokenId,
            tokenId: act.token.tokenId,
            collection: act.collection.collectionId,
            toAddress: act.toAddress,
            tokenImage: act.token.tokenImage,
          }));

          userActivityArray = userActivityArray.concat(responseDecoded);
          userActivityArray = userActivityArray.sort(
            (a, b) => b.timestamp - a.timestamp
          );
          userActivityArray.forEach((act) => {
            if (act.tokenImage == null || act.tokenImage === undefined) {
              console.log("image is null", act);
            }
          });
          setUserActivity(userActivityArray);
        })
        .catch((err) => console.error(err));
    }
  }
  useEffect(() => {
    if (address === undefined) return;
    // add badges with numbers on nft collection tiles
    // link accounts to eye.watch.account and nft images to eye.watch/tokenid
    // get owner accounts from firestore and sort them
    getUserActivity().then(() => calculateSpent());
  }, [address]);
  useEffect(() => {
    if (address === undefined) return;

    calculateSpent();
  }, [userActivity]);

  function openNftDetails(owner, collectionId, tokenId) {
    dispatch(setModalOpen());
    dispatch(
      setModalData({
        owner: owner,
        collectionId: collectionId,
        tokenId: tokenId,
      })
    );
    dispatch(setModalContent(ModalContent.NFT_DETAILS));
    ModalHistory.push({
      data: { owner: owner, collectionId: collectionId, tokenId: tokenId },
      content: ModalContent.NFT_DETAILS,
      isOpen: true,
    });
  }

  function generateRow(activity: any) {
    switch (activity.type) {
      case "sale":
        return (
          <div className={styles["row"]} key={uid()}>
            {(activity.toAddress as String).toLowerCase() ===
            address.toLowerCase() ? (
              <div className={styles["green-text"]}>Bought </div>
            ) : (
              <div className={styles["green-text"]}>Sold </div>
            )}
            <ImageFromUrl
              url={activity.tokenImage}
              onClick={() =>
                openNftDetails(null, activity.collection, activity.tokenId)
              }
            />
            {/* <NftThumbnail address={activity.contract} /> */}
            <div className={styles["middle"]}> {activity.tokenName}</div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );

      case "ask":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Asked </div>
            <ImageFromUrl
              url={activity.tokenImage}
              onClick={() =>
                openNftDetails(null, activity.collection, activity.tokenId)
              }
            />
            <div className={styles["middle"]}>
              for {activity.price} for {activity.tokenName}
            </div>{" "}
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
      case "transfer":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Transferred </div>
            <ImageFromUrl
              url={activity.tokenImage}
              onClick={() =>
                openNftDetails(null, activity.collection, activity.tokenId)
              }
            />
            <div className={styles["middle"]}> {activity.tokenName}</div>{" "}
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
      case "mint":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Minted </div>
            <ImageFromUrl
              url={activity.tokenImage}
              onClick={() =>
                openNftDetails(null, activity.collection, activity.tokenId)
              }
            />

            <div className={styles["middle"]}> {activity.tokenName}</div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
      case "bid":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Bid </div>
            <ImageFromUrl
              url={activity.tokenImage}
              onClick={() =>
                openNftDetails(null, activity.collection, activity.tokenId)
              }
            />

            <div className={styles["middle"]}>
              {activity.price} for {activity.tokenName}
            </div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
      case "bid_cancel":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Cancelled bid </div>
            <ImageFromUrl
              url={activity.tokenImage}
              onClick={() =>
                openNftDetails(null, activity.collection, activity.tokenId)
              }
            />

            <div className={styles["middle"]}> for {activity.tokenName}</div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
      case "ask_cancel":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Cancelled ask </div>
            <ImageFromUrl
              url={activity.tokenImage}
              onClick={() =>
                openNftDetails(null, activity.collection, activity.tokenId)
              }
            />

            <div className={styles["middle"]}>
              {activity.price} for {activity.tokenName}
            </div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
    }

    return (
      <div className={styles["row"]} key={uid()}>
        UNKNOWN ACTIVITY
      </div>
    );
  }

  return (
    <div>
      <div className={styles["title-label"]}>
        <div style={{ color: "#00D56E", paddingRight: "10px" }}>My</div>
        <div style={{ color: "#FFFFFF" }}>Activity</div>
      </div>
      <div className={styles["activity-component"]}>
        {address?.length > 5 ? (
          userActivity.map((activity) => generateRow(activity))
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
